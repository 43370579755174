import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorType } from '../lib/constants/errorTypes';

interface IProcessState {
    error: ErrorType | undefined;
    isLoading: boolean;
}

const initialState: IProcessState = {
    error: undefined,
    isLoading: true,
};

const psd2ControllerSlice = createSlice({
    name: 'psd2Controller',
    initialState,
    reducers: {
        setLoading(state: IProcessState, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setError(state: IProcessState, action: PayloadAction<ErrorType>) {
            state.isLoading = false;
            state.error = action.payload;
        },
        clearError(state: IProcessState) {
            state.error = undefined;
        },
    },
});

export const { clearError, setLoading } = psd2ControllerSlice.actions;

export const { reducer } = psd2ControllerSlice;

export default psd2ControllerSlice;
