import {
    DataControllerApi,
    Configuration,
    AmlQuestionnaireDataResponse,
    Psd2ServiceDataResponse,
    IdentyumServiceDataResponse,
    Psd2ServiceAccountsResponse,
    TosServiceDataResponse,
    Psd2ServiceAccountTransactionsResponse,
} from 'kent-process-monitor-api';
import Cookies from 'universal-cookie/es6';
import { AppSettings } from '../common/app-settings';

export const DataControllerClient = (): DataControllerApi => {
    const cookies = new Cookies();
    const token = cookies.get(AppSettings.ACCESS_TOKEN_OBJECT_COOKIE);
    const configuration: Configuration = new Configuration({ accessToken: token.accessToken, basePath: process.env.REACT_APP_PROCESS_MONITOR_URL });
    return new DataControllerApi(configuration);
};

export const getAmlData = async (sessionId: string): Promise<AmlQuestionnaireDataResponse> => {
    return DataControllerClient()
        .getAmlQuestionnaireData(sessionId)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const getBlacklistData = async (sessionId: string): Promise<Psd2ServiceDataResponse> => {
    return DataControllerClient()
        .getBlacklistCheckerData(sessionId)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const getIdentyumData = async (sessionId: string): Promise<IdentyumServiceDataResponse> => {
    return DataControllerClient()
        .getIdentyumServiceData(sessionId)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const getPsd2AccountsData = async (sessionId: string): Promise<Psd2ServiceAccountsResponse> => {
    return DataControllerClient()
        .getPsd2ServiceAccounts(sessionId)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const getPsd2TransactionsData = async (sessionId: string, accountId: string): Promise<Psd2ServiceAccountTransactionsResponse> => {
    return DataControllerClient()
        .getPsd2ServiceAccountTransactions(sessionId, accountId)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const getTosData = async (sessionId: string): Promise<TosServiceDataResponse> => {
    return DataControllerClient()
        .getTosServiceData(sessionId)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};
