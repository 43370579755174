import { useEffect, useState } from 'react';
import ErrorSnackbar from '../errorSnackbar';
import { Button, CircularProgress, Grid, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearError } from '../../slices/processMonitor';
import styles from './style.module.scss';
import { Roles } from '../../lib/constants/roles';
import { useAuth } from '../../hooks/useAuth';
import { setLoading } from '../../slices/psd2Controller';

const PSD2Settings: React.FC = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { userRoles } = useAuth();
    const { error, isLoading } = useAppSelector((state) => state.psd2Controller);
    const [minAvgSalary, setMinAvgSalary] = useState<string>('0');
    const [noOfSalaries, setNoOfSalaries] = useState<string>('0');

    useEffect(() => {
        dispatch(setLoading(false));
        if (error) {
            setTimeout(() => dispatch(clearError()), 4000);
        }
    }, [error]);

    return (
        <div className={styles.root}>
            {error && <ErrorSnackbar message={error} />}
            <Grid container direction="column" alignItems="start">
                {isLoading ? (
                    <div className="loaderMainDiv">
                        <div className="loaderBgDiv" />
                        <div className="loaderInnerDiv">
                            <div className="imgSize" style={{ position: 'relative' }}>
                                <CircularProgress color="secondary" />
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <TextField
                            name="psd2_min_avg_salary"
                            onChange={(e) => setMinAvgSalary(e.target.value)}
                            type="text"
                            label={t('psd2_min_avg_salary')}
                            value={minAvgSalary}
                            disabled={!userRoles.includes(Roles.psd2SettingsWrite)}
                            sx={{ maxWidth: 300, marginTop: 2 }}
                        />
                        <TextField
                            name="psd2_no_of_salaries"
                            onChange={(e) => setNoOfSalaries(e.target.value)}
                            type="text"
                            label={t('psd2_no_of_salaries')}
                            value={noOfSalaries}
                            disabled={!userRoles.includes(Roles.psd2SettingsWrite)}
                            sx={{ maxWidth: 300, marginTop: 2, marginBottom: 4 }}
                        />
                        <Button variant="contained" onClick={() => {}} disabled={!userRoles.includes(Roles.psd2SettingsWrite)}>
                            {t('save_btn')}
                        </Button>
                    </>
                )}
            </Grid>
        </div>
    );
};

export default PSD2Settings;
