import { RouteObject } from 'react-router';
import DashboardLayout from './components/dashboard/DashboardLayout';
import Processes from './components/Processes';
import ConsentOverview from './components/Consents/ConsentOverview';
import NoPrivileges from './components/Login/NoPrivileges';
import AuthGuard from './components/auth-guard/AuthGuard';
import PrivateRoute from './components/auth-guard/PrivateRoute';
import { Roles } from './lib/constants/roles';
import { AuthorizePage } from './components/Login/AuthorizePage';
import { AuthorizationCodeCallbackPage } from './components/Login/AuthorizationCodeCallbackPage';
import Identification from './components/Identification';
import PSD2Settings from './components/PSD2Settings';

const PROCESSES_ROUTE: RouteObject = {
    path: 'processes',
    element: (
        <AuthGuard>
            <DashboardLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/processes',
            element: (
                <PrivateRoute role={Roles.processMonitor}>
                    <Processes />
                </PrivateRoute>
            ),
        },
    ],
};

const IDENTIFICATION_ROUTE: RouteObject = {
    path: 'settings',
    element: (
        <AuthGuard>
            <DashboardLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/settings/identification',
            element: (
                <PrivateRoute role={Roles.requestView}>
                    <Identification />
                </PrivateRoute>
            ),
        },
        {
            path: '/settings/psd2',
            element: (
                <PrivateRoute role={Roles.requestView}>
                    <PSD2Settings />
                </PrivateRoute>
            ),
        },
    ],
};

const CONSENTS_ROUTE: RouteObject = {
    path: 'consents',
    element: (
        <AuthGuard>
            <DashboardLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/consents',
            element: (
                <PrivateRoute role={Roles.termsRead}>
                    <ConsentOverview />
                </PrivateRoute>
            ),
        },
    ],
};

const REFRESH_ROUTE: RouteObject = {
    path: 'refresh',
    element: <AuthorizePage />,
};

const LOGIN_ROUTE: RouteObject = {
    path: 'login',
    element: <AuthorizationCodeCallbackPage />,
};

const NO_PRIVILEGES_ROUTE: RouteObject = {
    path: 'no-privileges',
    element: <NoPrivileges />,
};

const HOME_ROUTE: RouteObject = {
    path: '/',
    element: (
        <AuthGuard>
            <DashboardLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <AuthorizePage />,
        },
    ],
};

const routes: RouteObject[] = [HOME_ROUTE, PROCESSES_ROUTE, IDENTIFICATION_ROUTE, CONSENTS_ROUTE, NO_PRIVILEGES_ROUTE, REFRESH_ROUTE, LOGIN_ROUTE];

export default routes;
