import { useEffect, useState } from 'react';
import ErrorSnackbar from '../errorSnackbar';
import { Box, CircularProgress, Grid, IconButton, Modal, Slider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearError } from '../../slices/processMonitor';
import styles from './style.module.scss';
import { getIdentificationReliabilityConfig, putIdentificationReliabilityConfig } from '../../slices/identyumController';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { IdentificationReliabilityConfig } from 'kent-identyum-service-api';
import { Roles } from '../../lib/constants/roles';
import { useAuth } from '../../hooks/useAuth';
import HelpIcon from '@mui/icons-material/Help';

enum ModalText {
    AGENT_FACE_MATCH = 'AGENT_FACE_MATCH',
    FACE_MATCH = 'FACE_MATCH',
    VIDEO_CHECK_RESULT = 'VIDEO_CHECK_RESULT',
}

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%', // Širina modala
    maxWidth: 600, // Maksimalna širina
    maxHeight: '80vh', // Maksimalna visina
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto', // Omogućava skrolanje
};

const Identification: React.FC = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { userRoles } = useAuth();
    const { error, isLoading, isLoadingPut, ratings } = useAppSelector((state) => state.identyumController);
    const [modalOpen, setModalOpen] = useState<ModalText | null>(null);
    const [readOnly, setReadOnly] = useState<boolean>(true);
    const [formState, setFormState] = useState<IdentificationReliabilityConfig | null>(null);

    useEffect(() => {
        setFormState(ratings);
    }, [ratings]);

    useEffect(() => {
        dispatch(getIdentificationReliabilityConfig());
    }, []);

    useEffect(() => {
        if (error) {
            setTimeout(() => dispatch(clearError()), 4000);
        }
    }, [error]);

    const handleClose = () => {
        setModalOpen(null);
    };

    const handleSave = () => {
        if (formState) {
            dispatch(putIdentificationReliabilityConfig(formState));
        }
    };

    const handleSliderChange = (key: keyof IdentificationReliabilityConfig) => (_event: Event, newValue: number | number[]) => {
        if (formState) {
            setFormState({ ...formState, [key]: newValue as number });
        }
    };

    return (
        <div className={styles.root}>
            {error && <ErrorSnackbar message={error} />}
            {/* Naslov sekcije */}
            <Typography variant="h4" sx={{ mb: 4, textAlign: 'center', fontWeight: 'bold' }}>
                {t('identification_reliability_section_title')} {/* Prijevod za "Pouzdanost identifikacije" */}
            </Typography>
            <Grid container direction="row" alignItems="center" justifyContent="space-around" className={styles.flexWrap}>
                {isLoading ? (
                    <div className="loaderMainDiv">
                        <div className="loaderBgDiv" />
                        <div className="loaderInnerDiv">
                            <div className="imgSize" style={{ position: 'relative' }}>
                                <CircularProgress color="secondary" />
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        {userRoles.includes(Roles.identificationReliabilityConfigWrite) && (
                            <>
                                <IconButton disabled={isLoadingPut} aria-label="edit" onClick={() => setReadOnly(!readOnly)} className={styles.edit}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton
                                    disabled={
                                        (ratings?.agentFaceMatch === formState?.agentFaceMatch &&
                                            ratings?.faceMatch === formState?.faceMatch &&
                                            ratings?.videoCheckResult === formState?.videoCheckResult) ||
                                        isLoadingPut
                                    }
                                    aria-label="edit"
                                    onClick={handleSave}
                                    className={styles.save}>
                                    <SaveIcon />
                                </IconButton>
                            </>
                        )}
                        {/* Agent Face Match */}
                        <Grid container direction="column" alignItems="center" justifyContent="center" width={'fit-content'}>
                            {/* Naslov iznad */}
                            <Typography component="legend" sx={{ mb: 1, fontWeight: 'bold' }}>
                                {t('agent_face_match')}
                            </Typography>
                            {/* Slider */}
                            <Slider
                                value={formState?.agentFaceMatch || 0}
                                min={0}
                                max={3}
                                step={1}
                                marks
                                disabled={readOnly || isLoadingPut}
                                onChange={handleSliderChange('agentFaceMatch')}
                                sx={{ width: 200 }}
                            />
                            {/* Broj ocjene ispod */}
                            <Typography variant="body2" sx={{ mt: 1 }}>
                                {formState?.agentFaceMatch || 0}
                            </Typography>
                            {/* Ikona za pomoć */}
                            <IconButton aria-label="help" onClick={() => setModalOpen(ModalText.AGENT_FACE_MATCH)} sx={{ mt: 1 }}>
                                <HelpIcon />
                            </IconButton>
                        </Grid>
                        {/* Face Match */}
                        <Grid container direction="column" alignItems="center" justifyContent="center" width={'fit-content'}>
                            {/* Naslov iznad */}
                            <Typography component="legend" sx={{ mb: 1, fontWeight: 'bold' }}>
                                {t('face_match')}
                            </Typography>
                            {/* Slider */}
                            <Slider
                                value={formState?.faceMatch || 0}
                                min={0}
                                max={5}
                                step={1}
                                marks
                                disabled={readOnly || isLoadingPut}
                                onChange={handleSliderChange('faceMatch')}
                                sx={{ width: 200 }}
                            />
                            {/* Broj ocjene ispod */}
                            <Typography variant="body2" sx={{ mt: 1 }}>
                                {formState?.faceMatch || 0}
                            </Typography>
                            {/* Ikona za pomoć */}
                            <IconButton aria-label="help" onClick={() => setModalOpen(ModalText.FACE_MATCH)} sx={{ mt: 1 }}>
                                <HelpIcon />
                            </IconButton>
                        </Grid>
                        {/* Video Check Result */}
                        <Grid container direction="column" alignItems="center" justifyContent="center" width={'fit-content'}>
                            {/* Naslov iznad */}
                            <Typography component="legend" sx={{ mb: 1, fontWeight: 'bold' }}>
                                {t('video_check_result')}
                            </Typography>
                            {/* Slider */}
                            <Slider
                                value={formState?.videoCheckResult || 0}
                                min={0}
                                max={3}
                                step={1}
                                marks
                                disabled={readOnly || isLoadingPut}
                                onChange={handleSliderChange('videoCheckResult')}
                                sx={{ width: 200 }}
                            />
                            {/* Broj ocjene ispod */}
                            <Typography variant="body2" sx={{ mt: 1 }}>
                                {formState?.videoCheckResult || 0}
                            </Typography>
                            {/* Ikona za pomoć */}
                            <IconButton aria-label="help" onClick={() => setModalOpen(ModalText.VIDEO_CHECK_RESULT)} sx={{ mt: 1 }}>
                                <HelpIcon />
                            </IconButton>
                        </Grid>
                    </>
                )}
            </Grid>
            {/* Modal za Agent Face Match */}
            <Modal open={modalOpen === ModalText.AGENT_FACE_MATCH} onClose={handleClose}>
                <Box sx={modalStyle}>
                    <Typography variant="h6" gutterBottom>
                        {t('agent_face_match_title')}
                    </Typography>
                    <Typography sx={{ whiteSpace: 'pre-line', mb: 2 }}>
                        {t('agent_face_match_intro')}
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                        {t('agent_face_match_ratings')}
                    </Typography>
                    <ul>
                        <li><strong>0</strong> – {t('agent_face_match_0')}</li>
                        <li><strong>1</strong> – {t('agent_face_match_1')}</li>
                        <li><strong>2</strong> – {t('agent_face_match_2')}</li>
                        <li><strong>3</strong> – {t('agent_face_match_3')}</li>
                    </ul>
                    <Typography sx={{ mt: 2 }}>
                        {t('agent_face_match_conclusion')}
                    </Typography>
                </Box>
            </Modal>
            {/* Modal za Face Match */}
            <Modal open={modalOpen === ModalText.FACE_MATCH} onClose={handleClose}>
                <Box sx={modalStyle}>
                    <Typography variant="h6">{t('face_match_title')}</Typography>
                    <Typography sx={{ whiteSpace: 'pre-line', mb: 2 }}>{t('face_match_intro')}</Typography>
                    <Typography variant="subtitle1">{t('face_match_ratings')}</Typography>
                    <ul>
                        <li><strong>0</strong> – {t('face_match_0')}</li>
                        <li><strong>1</strong> – {t('face_match_1')}</li>
                        <li><strong>2</strong> – {t('face_match_2')}</li>
                        <li><strong>3</strong> – {t('face_match_3')}</li>
                        <li><strong>4</strong> – {t('face_match_4')}</li>
                        <li><strong>5</strong> – {t('face_match_5')}</li>
                    </ul>
                    <Typography sx={{ mt: 2 }}>{t('face_match_conclusion')}</Typography>
                </Box>
            </Modal>
            {/* Modal za Video Check Result */}
            <Modal open={modalOpen === ModalText.VIDEO_CHECK_RESULT} onClose={handleClose}>
                <Box sx={modalStyle}>
                    <Typography variant="h6">{t('video_check_result_title')}</Typography>
                    <Typography sx={{ whiteSpace: 'pre-line', mb: 2 }}>{t('video_check_result_intro')}</Typography>
                    <Typography variant="subtitle1">{t('video_check_result_ratings')}</Typography>
                    <ul>
                        <li><strong>{t('video_check_result_not_possible')}</strong></li>
                        <li><strong>1</strong> – {t('video_check_result_1')}</li>
                        <li><strong>2</strong> – {t('video_check_result_2')}</li>
                        <li><strong>3</strong> – {t('video_check_result_3')}</li>
                    </ul>
                    <Typography sx={{ mt: 2 }}>{t('video_check_result_conclusion')}</Typography>
                </Box>
            </Modal>
        </div>
    );
};

export default Identification;