import { Roles } from '../lib/constants/roles';

export const getAvatarName = (username) => {
    let split = username.split('_');
    if (split.length > 1) {
        return username[0].toUpperCase() + split[split.length - 1][0].toUpperCase();
    }
    split = username.split('_');
    if (split.length > 1) {
        return username[0] + split[split.length - 1][0];
    }
    split = username.split('-');
    if (split.length > 1) {
        return username[0] + split[split.length - 1][0];
    }
};

export const getWithPrefixZero = (number: number): string => {
    return `${('0' + number).slice(-2)}`;
};

export const formatDate = (date: Date, delimiter?: string): string => {
    return [formatDateDate(date), formatDateTime(date)].join(delimiter ? delimiter : ' ');
};

export const formatDateDate = (date: Date, delimiter?: string): string => {
    return [getWithPrefixZero(date.getDate()), getWithPrefixZero(date.getMonth() + 1), date.getFullYear()].join(delimiter ? delimiter : '-');
};

export const formatDateTime = (date: Date, delimiter?: string): string => {
    return [getWithPrefixZero(date.getHours()), getWithPrefixZero(date.getMinutes()), getWithPrefixZero(date.getSeconds())].join(delimiter ? delimiter : ':');
};

export const getBase64Image = (base64Image: string): string => {
    return `data:image/png;base64,${base64Image}`;
};

export const getLandingPageByRole = (roles: string[]): string => {
    if (roles.includes(Roles.processMonitor)) {
        return '/processes';
    }
    if (roles.includes(Roles.termsRead)) {
        return '/consents';
    }
    return '/no-privileges';
};
