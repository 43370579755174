import type { FC, ReactNode } from 'react';
import { Box, Button, Collapse, ListItem, ListItemProps } from '@mui/material';
import { useState } from 'react';
import ChevronDownIcon from '../../assets/icons/chevron-down';
import ChevronRightIcon from '../../assets/icons/chevron-right';
import { NavLink as RouterLink } from 'react-router-dom';

interface INavItemProps extends ListItemProps {
    active?: boolean;
    children?: ReactNode;
    depth: number;
    icon?: ReactNode;
    info?: ReactNode;
    open?: boolean;
    path?: string;
    title: string;
}

const NavItem: FC<INavItemProps> = (props) => {
    const { active, children, depth, icon, info, open: openProp, path, title, ...other } = props;
    const [open, setOpen] = useState<boolean>(openProp ?? false);

    const toggleOpen = (): void => {
        setOpen((prevOpen) => !prevOpen);
    };

    let paddingLeft = 16;
    if (depth > 0) {
        paddingLeft = 32 + 8 * depth;
    }

    if (children) {
        return (
            <ListItem disableGutters sx={{ display: 'block', py: 0 }} {...other}>
                <Button
                    endIcon={open ? <ChevronDownIcon fontSize="small" /> : <ChevronRightIcon fontSize="small" />}
                    onClick={toggleOpen}
                    startIcon={icon}
                    sx={{
                        color: 'text.secondary',
                        fontWeight: 'fontWeightMedium',
                        justifyContent: 'flex-start',
                        pl: `${paddingLeft}px`,
                        pr: '8px',
                        py: '12px',
                        textAlign: 'left',
                        textTransform: 'none',
                        width: '100%',
                    }}
                    variant="text">
                    <Box sx={{ flexGrow: 1 }}>{title}</Box>
                    {info}
                </Button>
                <Collapse in={open}>{children}</Collapse>
            </ListItem>
        );
    }

    return (
        <ListItem disableGutters sx={{ display: 'flex', py: 0 }}>
            <Button
                component={path ? RouterLink : 'button'}
                startIcon={icon}
                sx={{
                    color: 'text.secondary',
                    fontWeight: 'fontWeightMedium',
                    justifyContent: 'flex-start',
                    pl: `${paddingLeft}px`,
                    pr: '8px',
                    py: '12px',
                    textTransform: 'none',
                    width: '100%',
                    ...(active && {
                        color: 'primary.main',
                        fontWeight: 'fontWeightBold',
                        '& svg': {
                            color: 'primary.main',
                        },
                    }),
                }}
                variant="text"
                to={path}>
                <Box sx={{ flexGrow: 1 }}>{title}</Box>
                {info}
            </Button>
        </ListItem>
    );
};

export default NavItem;
