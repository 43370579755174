import { combineReducers } from '@reduxjs/toolkit';
import { reducer as processMonitorReducer } from '../slices/processMonitor';
import { reducer as dataControllerReducer } from '../slices/dataController';
import { reducer as tosControllerReducer } from '../slices/tosController';
import { reducer as identyumControllerReducer } from '../slices/identyumController';
import { reducer as psd2ControllerReducer } from '../slices/psd2Controller';

const rootReducer = combineReducers({
    processMonitor: processMonitorReducer,
    dataController: dataControllerReducer,
    tosController: tosControllerReducer,
    identyumController: identyumControllerReducer,
    psd2Controller: psd2ControllerReducer,
});

export default rootReducer;
