import ReactDOM from "react-dom/client";
import './lib/style/index.scss';
import './index.css';
import App from './App';
import { SettingsProvider } from './context/SettingsContext';
import { BrowserRouter } from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material';
import { AuthProvider } from './components/auth-guard/JWTContext';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from './store';

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <ReduxProvider store={store}>
        <StyledEngineProvider>
            <SettingsProvider>
                <BrowserRouter>
                    <AuthProvider>
                        <App />
                    </AuthProvider>
                </BrowserRouter>
            </SettingsProvider>
        </StyledEngineProvider>
    </ReduxProvider>
);
