import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import * as IdentyumServiceApi from '../api/kentIdentyumServiceApi';
import { ErrorType } from '../lib/constants/errorTypes';
import { IdentificationReliabilityConfig } from 'kent-identyum-service-api';

interface IProcessState {
    error: ErrorType | undefined;
    isLoading: boolean;
    isLoadingPut: boolean;
    ratings: IdentificationReliabilityConfig | null;
}

const initialState: IProcessState = {
    error: undefined,
    isLoading: true,
    isLoadingPut: false,
    ratings: {
        agentFaceMatch: 1,
        faceMatch: 1,
        videoCheckResult: 1,
    },
};

const identyumControllerSlice = createSlice({
    name: 'identyumController',
    initialState,
    reducers: {
        getIdentificationReliabilityConfig(state: IProcessState, action: PayloadAction<IdentificationReliabilityConfig>) {
            state.ratings = {
                agentFaceMatch: action.payload.agentFaceMatch,
                faceMatch: action.payload.faceMatch,
                videoCheckResult: action.payload.videoCheckResult,
            };
            state.isLoading = false;
        },
        putIdentificationReliabilityConfig(state: IProcessState, action: PayloadAction<IdentificationReliabilityConfig>) {
            state.ratings = {
                agentFaceMatch: action.payload.agentFaceMatch,
                faceMatch: action.payload.faceMatch,
                videoCheckResult: action.payload.videoCheckResult,
            };
            state.isLoadingPut = false;
        },
        setLoading(state: IProcessState, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setPutLoading(state: IProcessState, action: PayloadAction<boolean>) {
            state.isLoadingPut = action.payload;
        },
        setError(state: IProcessState, action: PayloadAction<ErrorType>) {
            state.isLoading = false;
            state.isLoadingPut = false;
            state.error = action.payload;
        },
        clearError(state: IProcessState) {
            state.error = undefined;
        },
    },
});

export const getIdentificationReliabilityConfig =
    (): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(identyumControllerSlice.actions.setLoading(true));
        try {
            const searchResponse = await IdentyumServiceApi.getIdentificationReliabilityConfig();
            dispatch(identyumControllerSlice.actions.getIdentificationReliabilityConfig(searchResponse));
        } catch (error: any) {
            dispatch(identyumControllerSlice.actions.setError(ErrorType[error?.response?.data?.type] ?? ErrorType.GENERIC));
        }
    };

export const putIdentificationReliabilityConfig =
    (identificationReliabilityConfig: IdentificationReliabilityConfig): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(identyumControllerSlice.actions.setPutLoading(true));
        try {
            await IdentyumServiceApi.putIdentificationReliabilityConfig(identificationReliabilityConfig);
            dispatch(identyumControllerSlice.actions.putIdentificationReliabilityConfig(identificationReliabilityConfig));
        } catch (error: any) {
            dispatch(identyumControllerSlice.actions.setError(ErrorType[error?.response?.data?.type] ?? ErrorType.GENERIC));
        }
    };

export const { clearError } = identyumControllerSlice.actions;

export const { reducer } = identyumControllerSlice;

export default identyumControllerSlice;
