import { ProcessMonitorControllerApi, Configuration, SearchResponse } from 'kent-process-monitor-api';
import Cookies from 'universal-cookie/es6';
import { AppSettings } from '../common/app-settings';

export const ProcessMonitorControllerClient = (): ProcessMonitorControllerApi => {
    const cookies = new Cookies();
    const token = cookies.get(AppSettings.ACCESS_TOKEN_OBJECT_COOKIE);
    const configuration: Configuration = new Configuration({ accessToken: token.accessToken, basePath: process.env.REACT_APP_PROCESS_MONITOR_URL });
    return new ProcessMonitorControllerApi(configuration);
};

export const getProcesses = async (
    fromRow: number,
    rowsPerPage: number,
    startDate: string,
    finishDate: string,
    showOnlyErrors: boolean,
    searchTerm: string
): Promise<SearchResponse> => {
    return ProcessMonitorControllerClient()
        .getProcesses(fromRow, rowsPerPage, startDate, finishDate, showOnlyErrors, searchTerm)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};
