import { Grid, Rating, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { Process } from 'kent-process-monitor-api';
import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';
import { format } from 'date-fns';

interface Props {
    isExpanded: boolean;
    process: Process;
}

const InfoDetails: React.FC<Props> = ({ isExpanded, process }) => {
    const { t } = useTranslation();

    return (
        <TableRow className={styles.root} style={{ display: isExpanded ? 'table-row' : 'none' }}>
            <TableCell colSpan={9}>
                <Grid container justifyContent="center" alignItems="center">
                    {process?.personalData && (
                        <Grid item xs={12} md={9} className={styles.titles}>
                            <h3>{t('processes_personaldata')}</h3>
                            <TableContainer>
                                <Table aria-label="simple table">
                                    <TableBody>
                                        {process?.personalData?.firstName && (
                                            <TableRow>
                                                <TableCell align="center">{t('requests_grid_name')}</TableCell>
                                                <TableCell align="center">{process?.personalData?.firstName}</TableCell>
                                            </TableRow>
                                        )}
                                        {process?.personalData?.lastName && (
                                            <TableRow>
                                                <TableCell align="center">{t('requests_grid_family')}</TableCell>
                                                <TableCell align="center">{process?.personalData?.lastName}</TableCell>
                                            </TableRow>
                                        )}
                                        {process?.personalData?.personalNumber && (
                                            <TableRow>
                                                <TableCell align="center">OIB</TableCell>
                                                <TableCell align="center">{process?.personalData?.personalNumber}</TableCell>
                                            </TableRow>
                                        )}
                                        {process?.personalData?.documentNumber && (
                                            <TableRow>
                                                <TableCell align="center">{t('processes_docnumber')}</TableCell>
                                                <TableCell align="center">{process?.personalData?.documentNumber}</TableCell>
                                            </TableRow>
                                        )}
                                        {process?.personalData?.identificationReliability && (
                                            <TableRow>
                                                <TableCell align="center">{t('processes_identificationreliability')}</TableCell>
                                                <TableCell>
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        alignItems="center"
                                                        my={2}
                                                        justifyContent="space-around"
                                                        sx={{ flexWrap: 'wrap' }}>
                                                        <Grid
                                                            container
                                                            direction="column"
                                                            alignItems="center"
                                                            justifyContent="space-around"
                                                            width={'fit-content'}>
                                                            <Typography component="legend">{t('agent_face_match')}</Typography>
                                                            <Rating
                                                                name="simple-controlled"
                                                                value={parseInt(process?.personalData?.identificationReliability.agentFaceMatch || '') || 0}
                                                                precision={1}
                                                                max={3}
                                                                readOnly={true}
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            container
                                                            direction="column"
                                                            alignItems="center"
                                                            justifyContent="space-around"
                                                            width={'fit-content'}>
                                                            <Typography component="legend">{t('face_match')}</Typography>
                                                            <Rating
                                                                name="simple-controlled"
                                                                value={parseInt(process?.personalData?.identificationReliability.faceMatch || '') || 0}
                                                                precision={1}
                                                                max={5}
                                                                readOnly={true}
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            container
                                                            direction="column"
                                                            alignItems="center"
                                                            justifyContent="space-around"
                                                            width={'fit-content'}>
                                                            <Typography component="legend">{t('video_check_result')}</Typography>
                                                            <Rating
                                                                name="simple-controlled"
                                                                value={parseInt(process?.personalData?.identificationReliability.videoCheckResult || '') || 0}
                                                                precision={1}
                                                                max={3}
                                                                readOnly={true}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    )}
                    {process?.networkData && (
                        <Grid item xs={12} md={9} className={styles.titles}>
                            <h3>{t('processes_networkdata')}</h3>
                            <TableContainer>
                                <Table aria-label="simple table">
                                    <TableBody>
                                        {process?.networkData?.ip && (
                                            <TableRow>
                                                <TableCell align="center">{t('processes_ipaddress')}</TableCell>
                                                <TableCell align="center">{process?.networkData?.ip}</TableCell>
                                            </TableRow>
                                        )}
                                        {process?.networkData?.userAgent && (
                                            <TableRow>
                                                <TableCell align="center">{t('processes_useragent')}</TableCell>
                                                <TableCell align="center">{process?.networkData?.userAgent}</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    )}
                    {process?.errors && (
                        <Grid item xs={12} md={9} className={styles.titles}>
                            <h3>{t('processes_errors')}</h3>
                            <TableContainer>
                                <Table aria-label="simple table">
                                    {process?.errors.map((err: any, index: number) => (
                                        <TableBody key={index}>
                                            {err?.facility && (
                                                <TableRow>
                                                    <TableCell align="center">{t('processes_errors_facility')}</TableCell>
                                                    <TableCell align="center">{err?.facility}</TableCell>
                                                </TableRow>
                                            )}
                                            {err?.reason && (
                                                <TableRow>
                                                    <TableCell align="center">{t('processes_errors_reason')}</TableCell>
                                                    <TableCell align="center">{err?.reason}</TableCell>
                                                </TableRow>
                                            )}
                                            {err?.time && (
                                                <TableRow>
                                                    <TableCell align="center">{t('processes_errors_time')}</TableCell>
                                                    <TableCell align="center">{format(new Date(err?.time), 'dd.MM.yyyy. HH:mm')}</TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    ))}
                                </Table>
                            </TableContainer>
                        </Grid>
                    )}
                </Grid>
            </TableCell>
        </TableRow>
    );
};

export default InfoDetails;
