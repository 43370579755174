import { useEffect } from 'react';
import { Grid, Rating, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getIdyData } from '../../../slices/dataController';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

interface Props {
    sessionId: string;
    isExpanded: boolean;
}

const IdentyumService: React.FC<Props> = ({ sessionId, isExpanded }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { idyData } = useAppSelector((state) => state.dataController);

    useEffect(() => {
        if (isExpanded) {
            dispatch(getIdyData(sessionId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExpanded]);

    return (
        <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} md={9}>
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableBody>
                            <TableRow>
                                <TableCell align="center">{t('orderinfo_fullname')}</TableCell>
                                <TableCell align="center">{idyData?.personalData?.fullName ?? '-'}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">{t('processes_dateofbirth')}</TableCell>
                                <TableCell align="center">{idyData?.personalData?.dateOfBirth ?? '-'}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">{t('processes_sex')}</TableCell>
                                <TableCell align="center">{idyData?.personalData?.sex}</TableCell>
                            </TableRow>
                            {idyData?.personalData?.personalNumbers.map((personalNum) => (
                                <TableRow key={personalNum.value}>
                                    <TableCell align="center">{personalNum.type ?? '-'}</TableCell>
                                    <TableCell align="center">{personalNum.value ?? '-'}</TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell align="center">{t('processes_nationalitycode')}</TableCell>
                                <TableCell align="center">{idyData?.personalData?.nationalityCode ?? '-'}</TableCell>
                            </TableRow>
                            {idyData?.personalData?.addresses.map((address) => (
                                <TableRow key={address.value}>
                                    <TableCell align="center">{address.type ?? '-'}</TableCell>
                                    <TableCell align="center">{address.value ?? '-'}</TableCell>
                                </TableRow>
                            ))}
                            {idyData?.personalData?.identificationReliability && (
                                <TableRow>
                                    <TableCell align="center">{t('processes_identificationreliability')}</TableCell>
                                    <TableCell>
                                        <Grid container direction="row" alignItems="center" my={2} justifyContent="space-around" sx={{ flexWrap: 'wrap' }}>
                                            <Grid container direction="column" alignItems="center" justifyContent="space-around" width={'fit-content'}>
                                                <Typography component="legend">{t('agent_face_match')}</Typography>
                                                <Rating
                                                    name="simple-controlled"
                                                    value={parseInt(idyData?.personalData?.identificationReliability.agentFaceMatch || '') || 0}
                                                    precision={1}
                                                    max={3}
                                                    readOnly={true}
                                                />
                                            </Grid>
                                            <Grid container direction="column" alignItems="center" justifyContent="space-around" width={'fit-content'}>
                                                <Typography component="legend">{t('face_match')}</Typography>
                                                <Rating
                                                    name="simple-controlled"
                                                    value={parseInt(idyData?.personalData?.identificationReliability.faceMatch || '') || 0}
                                                    precision={1}
                                                    max={5}
                                                    readOnly={true}
                                                />
                                            </Grid>
                                            <Grid container direction="column" alignItems="center" justifyContent="space-around" width={'fit-content'}>
                                                <Typography component="legend">{t('video_check_result')}</Typography>
                                                <Rating
                                                    name="simple-controlled"
                                                    value={parseInt(idyData?.personalData?.identificationReliability.videoCheckResult || '') || 0}
                                                    precision={1}
                                                    max={3}
                                                    readOnly={true}
                                                />
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            {idyData?.personalData?.documents.map((document) => (
                <Grid key={document?.number} container xs={12} md={12} style={{ marginTop: '1rem' }}>
                    <Grid container xs={6} md={6} direction="column" justifyContent="center" alignItems="center">
                        <img src={`data:image/png;base64,${document.frontImage?.base64Value}`} alt="front_image" width={300} />
                        <img src={`data:image/png;base64,${document.backImage?.base64Value}`} alt="back_image" width={300} />
                    </Grid>
                    <Grid container xs={6} md={6} direction="column" justifyContent="center" alignItems="center">
                        <TableRow>
                            <TableCell align="center">{t('consents_doctype')}</TableCell>
                            <TableCell align="center">{document?.type ?? '-'}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center">{t('processes_docnumber')}</TableCell>
                            <TableCell align="center">{document?.number ?? '-'}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center">{t('processes_dateofissue')}</TableCell>
                            <TableCell align="center">{document?.dateOfIssue ?? '-'}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center">{t('processes_dateofexpiry')}</TableCell>
                            <TableCell align="center">{document?.dateOfExpiry ?? '-'}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center">{t('processes_issuingcountrycode')}</TableCell>
                            <TableCell align="center">{document?.issuingCountryCode ?? '-'}</TableCell>
                        </TableRow>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
};

export default IdentyumService;
