import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    hr: {
        translation: {
            links_title: 'POVEZNICE',
            links_documents: 'DOKUMENTI',
            links_processes: 'PROCESI',
            links_settings: 'POSTAVKE',
            links_settings_identification: 'POUZDANOST IDENTIFIKACIJE',
            links_settings_psd2: 'PSD2 FINANCIJSKE POSTAVKE',
            account_logout: 'ODJAVA',
            account_mode_one: 'Tamna tema je UKLJUČENA',
            account_mode_two: 'Tamna tema je ISKLJUČENA',
            language_hr: 'Hrvatski',
            language_en: 'Engleski',
            requests_from: 'Od:',
            requests_to: 'Do:',
            requests_status_all: 'SVI',
            requests_status_initiated: 'ZAPOČETI',
            requests_status_completed: 'ZAVRŠENI',
            requests_status_rejected: 'ODBIJENI',
            requests_merchant: 'TRGOVAC',
            requests_search: 'PRETRAŽI',
            requests_grid_created: 'Kreirano',
            requests_grid_name: 'Ime',
            requests_grid_family: 'Prezime',
            requests_grid_amount: 'Iznos',
            requests_grid_merchant: 'Trgovac',
            grid_rowsperpage: 'Redova po stranici:',
            back_button: 'Natrag',
            orderinfo_clientinfo: 'Informacije o klijentu',
            orderinfo_fullname: 'Ime i prezime',
            orderinfo_email: 'E-mail adresa',
            orderinfo_phone: 'Broj telefona',
            orderinfo_city: 'Grad',
            orderinfo_address: 'Adresa',
            orderinfo_idnumber: 'Broj osobne iskaznice',
            orderinfo_dateofexpiry: 'Datum isteka osobne iskaznice',
            orderinfo_requestinfo: 'Informacije o zahtjevu',
            orderinfo_requestpaymenttype: 'Način plaćanja',
            consents_newtype: 'Novi tip dokumenta',
            consents_activefilter: 'Prikaži neaktivne',
            consents_doctype: 'Tip dokumenta',
            consents_updated: 'Ažurirano',
            consents_enabled: 'Aktivno',
            consents_required: 'Obavezno',
            edit_button: 'UREDI',
            details_button: 'DETALJI',
            lastedited: 'Posljednje uređivano:',
            active: 'Aktivno',
            activate: 'Aktiviraj',
            required: 'Obavezno',
            addnewdoc: 'Dodaj novi dokument',
            dragndrop_text: 'Povuci datoteku ovdje ili klikni',
            dragndrop_tooLargeFile: 'Veličina datoteke je prevelika',
            dragndrop_notSupportedAttachment: 'Podržan je samo PDF tip datoteka',
            dragndrop_selectFile: 'Odaberi datoteku',
            dragndrop_noFilesAddedPrimary: 'Nije dodana nijedna datoteka',
            dragndrop_noFilesAddedSecondary: 'Možete dodati datoteku povlačenjem na polje iznad ili klikom na polje',
            please_try_again: 'Molimo pokušajte ponovno',
            send_button: 'Pošalji',
            sending: 'Slanje',
            last_change: 'Zadnja promjena',
            consents_addgroup: 'Dodaj novu grupu dokumenata',
            consents_uploaddoc: 'Učitaj novi dokument',
            name: 'Naziv',
            processes_erroronly: 'Prikaži samo greške',
            date: 'Datum',
            processes_personaldata: 'Osobni podaci',
            processes_docnumber: 'Broj dokumenta',
            processes_networkdata: 'Mrežni podaci',
            processes_errors: 'Greške',
            processes_errors_facility: 'Korak',
            processes_errors_reason: 'Razlog',
            processes_errors_time: 'Vrijeme',
            processes_ipaddress: 'IP adresa',
            processes_useragent: 'Korisnički agent',
            processes_dateofbirth: 'Datum rođenja',
            processes_sex: 'Spol',
            processes_nationalitycode: 'Kod nacionalnosti',
            processes_dateofissue: 'Datum izdavanja',
            processes_dateofexpiry: 'Datum isteka',
            processes_issuingcountrycode: 'Kod države izdavanja',
            processes_identificationreliability: 'Pouzdanost identifikacije',
            tos_acceptedconsents: 'Prihvaćene suglasnosti:',
            pdfpage: 'od',
            psd2_currency: 'Valuta',
            psd2_displayname: 'Naziv za prikaz',
            psd2_cashacctype: 'Tip gotovinskog računa',
            psd2_usage: 'Upotreba',
            psd2_ownername: 'Vlasnik',
            psd2_balancetype: 'Tip salda',
            psd2_amount: 'Saldo',
            psd2_creditlimit: 'Kreditni limit',
            psd2_transactionid: 'ID transakcije',
            psd2_entryreference: 'Referenca unosa',
            psd2_bookingdate: 'Datum knjiženja',
            psd2_valuedate: 'Datum valute',
            psd2_transactionamount: 'Iznos transakcije',
            psd2_creditorname: 'Ime vjerovnika',
            psd2_creditoriban: 'IBAN vjerovnika',
            psd2_ultimatecreditor: 'Stvarni vjerovnik',
            psd2_debtorname: 'Ime dužnika',
            psd2_debtoriban: 'IBAN dužnika',
            psd2_ultimatedebtor: 'Stvarni dužnik',
            psd2_transactioninfo: 'Opis transakcije',
            psd2_additionalinfo: 'Dodatni opis transakcije',
            psd2_purposecode: 'Kod svrhe',
            psd2_sourcecurrency: 'Izvorna valuta',
            psd2_targetcurrency: 'Ciljna valuta',
            psd2_exchangerate: 'Tečaj',
            agent_face_match: 'Provjera lica - agent',
            face_match: 'Provjera lica',
            video_check_result: 'Rezultat video provjere',
            psd2_min_avg_salary: 'Minimalna prosječna plaća',
            psd2_no_of_salaries: 'Broj potrebnih plaća',
            identification_reliability_section_title: 'Pouzdanost Identifikacije',
            save_btn: 'Spremi',
            agent_face_match_title: "Ocjenjivanje podudarnosti lica",
            agent_face_match_intro: "Službenik za registraciju ocjenjuje stupanj podudarnosti selfie fotografije s fotografijom iz osobnog dokumenta.",
            agent_face_match_ratings: "Ocjene:",
            agent_face_match_0: "Sigurno nije ista osoba",
            agent_face_match_1: "Vjerojatno nije ista osoba",
            agent_face_match_2: "Vjerojatno je ista osoba (FAR 1/50.000 pri FRR od 2%)",
            agent_face_match_3: "Vrlo vjerojatno je ista osoba (FAR 1/100.000 pri FRR od 5%)",
            agent_face_match_conclusion: "Ocjene 2 i 3 omogućuju nastavak procesa, dok ocjene 0 i 1 zahtijevaju ponavljanje postupka.",
            face_match_title: "Ocjenjivanje podudarnosti lica (FaceMatch)",
            face_match_intro: "Facematch metodom Identyum uspoređuje selfie reprezentaciju lica (dobivenu tijekom 3D FaceScan snimke) osobe sa 2D slikom (fotografijom lica) očitanom sa dokumenta ili dobivenu iz nekog drugog neovisnog i vjerodostojnog izvora, pri tome koristeći dvije metode:",
            face_match_ratings: "Ocjene FaceTec algoritma i njihovo značenje:",
            face_match_0: "Neuspješna usporedba – vrlo loša kvaliteta fotografije ili se ne radi o istoj osobi",
            face_match_1: "Uspješna usporedba, no niske pouzdanosti – moguć prolaz osoba vrlo sličnog lica (otac-sin, majka-kćer i sl.) 1/100 FAR pri <1% FRR",
            face_match_2: "Uspješna usporedba, umjerene pouzdanosti – vrlo male šanse za prolaz osoba sličnog lica 1/250 FAR pri <1% FRR",
            face_match_3: "Uspješna usporedba, dobre pouzdanosti 1/500 FAR pri <1% FRR",
            face_match_4: "Uspješna usporedba, visoke pouzdanosti 1/1.000 FAR pri <1% FRR",
            face_match_5: "Uspješna usporedba, vrlo visoke pouzdanosti 1/10.000 FAR pri <1% FRR",
            face_match_conclusion: "Ocjene 2 i više smatraju se prolaznim, dok se u slučaju ocjena 0 i 1 preporučuje ponavljanje procesa.",
            video_check_result_title: "Ocjena autentičnosti identifikacijskog dokumenta (Video Check Result)",
            video_check_result_intro: "Službenik za registraciju provodi analizu snimke rotiranja i donosi opću ocjenu autentičnosti predočenog identifikacijskog dokumenta na temelju 8 promatranih optičkih kriterija (hologram, laminacija, font, fotografija osobe, vizualni pregled, oštećenje, tinta, boja).",
            video_check_result_ratings: "Ocjene autentičnosti i njihovo značenje:",
            video_check_result_not_possible: "Nemoguća provjera autentičnosti – vrlo loša kvaliteta video snimke ili se očito ne radi o izvornom identifikacijskom dokumentu.\n\nMogući razlozi:\n- Nema slike\n- Video stream nije neprekinut\n- Nije predočen identifikacijski dokument ili je predočen nepodržan identifikacijski dokument\n- Dokument nije autentičan (fotokopija, fotografija dokumenta)\n- Jako oštećen dokument",
            video_check_result_1: "Vjerojatno autentičan dokument, no vrlo niska pouzdanost procjene njegove autentičnosti.\n\nMogući razlozi:\n- Kvaliteta video snimke je izuzetno niska npr. zbog loše kamere\n- Identifikacijski dokument je zamućen na snimci\n- Uvjeti snimanja su loši (pretamna prostorija ili previše odbljeska)\n- Dokument se snima sa premale ili prevelike razdaljine\n- Dokument je vjerojatno autentičan, ali je znatno oštećen (nenamjerno)",
            video_check_result_2: "Autentičan dokument uz umjerenu pouzdanost procjene njegove autentičnosti.\n\n- Krajnji korisnik je obavio sve radnje rotacije na ispravan način\n- Svi promatrani optički elementi identifikacijskog dokumenta su prepoznatljivi\n- Svojstva holograma i laminacije su vidljiva, ali ne potpuno jasna (npr. zbog 'flat' svjetla)\n- Elementi tinte i fonta izgledaju ispravno\n- FAR 1/50.000 pri FRR od 2%",
            video_check_result_3: "Autentičan dokument uz vrlo visoku pouzdanost procjene njegove autentičnosti.\n\n- Kvaliteta snimke je visoka\n- Krajnji korisnik je obavio sve radnje rotacije na ispravan način\n- Svi promatrani optički elementi identifikacijskog dokumenta su prepoznatljivi\n- Svojstva holograma i laminacije su jasno vidljiva (presijavanje i debljina laminacije)\n- Pozicija fotografije osobe sa sigurnosnim elementima je jasno prepoznatljiva\n- Elementi tinte i fonta izgledaju ispravno\n- FAR 1/100.000 pri FRR od 20%",
            video_check_result_conclusion: "Ocjene 2 i 3 su prolazne ocjene, te se Korisnik prosljeđuje u daljnji proces. U slučaju ocjene 1 ili ako ocjena nije moguća, Korisnik se upućuje na ponavljanje procesa."
}
        }
    }
i18n.use(initReactI18next).init({
    resources,
    lng: 'hr',
    fallbackLng: 'hr',
    interpolation: {
        escapeValue: false,
    },
});
